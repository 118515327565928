/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page-wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > * {
    flex: 1 1 auto;
  }
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}
